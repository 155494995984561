const ReviewsData =[
{
    id: 0,
    headLink:"https://99rdp.com/",
    headText:"99RDP",
    featureText1:"1 vCPU",
    featureText2:"32-Core 64-T @3 GHz",
    featureText3:"1 TB  NVMe SSD Disk",
    featureText4:"Unlimited Bandwidth",
    ratingNum:"9.8",
    userText:"225",
    Cardprice:"$5.99"
},
{
    id: 1,
    headLink:"https://amazingrdp.com/",
    headText:"AmazingRDP",
    featureText1:"Intel® Core™ I7-4770",
    featureText2:"1Gbps Internet Speed",
    featureText3:"Unlimited Bandwidth",
    featureText4:"No Admin Access",
    ratingNum:"9.5",
    userText:"205",
    Cardprice:"$4.99"
},
{
    id: 2,
    headLink:"https://eurdp.com/",
    headText:"EURRDP",
    featureText1:"AMD EPYC 7502P",
    featureText2:"128 GB DDR4 ECC RAM",
    featureText3:"Full Root Admin Access",
    featureText4:"99.9% Service Uptime",
    ratingNum:"7.4",
    userText:"150",
    Cardprice:"$6.89"
},
{
    id: 3,
    headLink:"https://homerdp.com/",
    headText:"HomeRDP",
    featureText1:"1vCPU",
    featureText2:"52 GB DDR4 RAM",
    featureText3:"15 GB NVMe SSD",
    featureText4:"1 TB Bandwidth",
    ratingNum:"8.8",
    userText:"200",
    Cardprice:"$9.99"
},
{
    id: 4,
    headLink:"https://gpurdp.com/",
    headText:"GPURDP",
    featureText1:"2 vCPU 2.60Ghz",
    featureText2:"4 GB RAM",
    featureText3:"50 GB SDD Disk",
    featureText4:"1 TB Bandwidth",
    ratingNum:"7.4",
    userText:"120",
    Cardprice:"$20.00"
},
{
    id: 5,
    headLink:"https://rdpdatabase.com/",
    headText:"RDPDatabase",
    featureText1:"Intel Core i7-6700 @ 3.40 GHz",
    featureText2:"4 Cores 8 Threads",
    featureText3:"No Admin Access",
    featureText4:"64 GB RAM",
    ratingNum:"8.0",
    userText:"180",
    Cardprice:"$5.99"
},
{
    id: 6,
    headLink:"https://pcrdp.com/",
    headText:"PCRDP",
    featureText1:"Intel Core i7-6700 @ 3.40 GHz",
    featureText2:"4 Cores 8 Threads",
    featureText3:"No Admin Access",
    featureText4:"64 GB RAM",
    ratingNum:"8.4",
    userText:"170",
    Cardprice:"$5.99"
},
{
    id: 7,
    headLink:"https://gordp.com/",
    headText:"GoRDP",
    featureText1:"1 vCPU 3.40Ghz",
    featureText2:"1 Gbps Internet Speed",
    featureText3:"2 GB DDR4 RAM",
    featureText4:"30 GB SSD Storage",
    ratingNum:"7.0",
    userText:"100",
    Cardprice:"$5.99"
},
{
    id: 8,
    headLink:"https://99server.com/",
    headText:"99Server",
    featureText1:"Intel®️ Core™️ i9-11900K(3.5 GHz, 8 cores)",
    featureText2:"I16 GB DDR4 RAM(2 x 8 GB)",
    featureText3:"512 GB PCIe® NVMe™ SSD",
    featureText4:"1 Gbps internet port",
    ratingNum:"7.8",
    userText:"140",
    Cardprice:"$49"
},
{
    id: 9,
    headLink:"https://olivevps.com/",
    headText:"OliveVPS",
    featureText1:"4 CPU Cores",
    featureText2:"8 GB RAM",
    featureText3:"200 GB NVMe SSD Storage",
    featureText4:"Linux only",
    ratingNum:"7.6",
    userText:"130",
    Cardprice:"$19.99"
},
{
    id: 10,
    headLink:"https://serverscafe.com/",
    headText:"ServerCafe",
    featureText1:"Intel Xeon-D 2123IT",
    featureText2:"4C/8T – 3.0 GHz Turbo",
    featureText3:"32 GB DDR4 RAM",
    featureText4:"2 x 500 GB SSD Storage (RAID-1)",
    ratingNum:"7.2",
    userText:"90",
    Cardprice:"$199"
},

]

export default ReviewsData