const CyberMondayList = [
    {
        id: 0,
        offText: "10% Off",
        rdpName: "99RDP",
        cardTitle: "10% OFF On VPS/ monthly billing cycle",
        verifyText: "Verified Yesterday",
        usesText: "61 Uses Today",
        BoxDesc: "Avail this offer and save big bucks on your reliable and cost-effective RDP services.",
        btnLink: "https://99rdp.com/",
        modalDesc: "Copy & paste the following code at checkout on",
        modalCode: "R6D6P6",
    },
    {
        id: 1,
        offText: "20% Off",
        rdpName: "99RDP",
        cardTitle: "20% OFF on DS/Monthly billing cycle",
        verifyText: "Verified Yesterday",
        usesText: "61 Uses Today",
        BoxDesc: "Avail this offer and save big bucks on your reliable and cost-effective RDP services.",
        btnLink: "https://99rdp.com/",
        modalDesc: "Copy & paste the following code at checkout on",
        modalCode: "R6D6P6",
    },
    {
        id: 2,
        offText: "10% Off",
        rdpName: "AmazingRDP",
        cardTitle: "10% OFF On VPS/ monthly billing cycle",
        verifyText: "Verified Yesterday",
        usesText: "61 Uses Today",
        BoxDesc: "Avail this offer and save big bucks on your reliable and cost-effective RDP services.",
        btnLink: "https://amazingrdp.com/",
        modalDesc: "Copy & paste the following code at checkout on",
        modalCode: "R6D6P6",
    },
    {
        id: 3,
        offText: "20% Off",
        rdpName: "AmazingRDP",
        cardTitle: "20% OFF on DS/Monthly billing cycle",
        verifyText: "Verified Yesterday",
        usesText: "61 Uses Today",
        BoxDesc: "Avail this offer and save big bucks on your reliable and cost-effective RDP services.",
        btnLink: "https://amazingrdp.com/",
        modalDesc: "Copy & paste the following code at checkout on",
        modalCode: "R6D6P6",
    },
    {
        id: 4,
        offText: "10% Off",
        rdpName: "HomeRDP",
        cardTitle: "10% OFF On VPS/ monthly billing cycle",
        verifyText: "Verified Yesterday",
        usesText: "61 Uses Today",
        BoxDesc: "Avail this offer and save big bucks on your reliable and cost-effective RDP services.",
        btnLink: "https://homerdp.com/",
        modalDesc: "Copy & paste the following code at checkout on",
        modalCode: "R6D6P6",
    },
    {
        id: 5,
        offText: "20% Off",
        rdpName: "HomeRDP",
        cardTitle: "20% OFF on DS/Monthly billing cycle",
        verifyText: "Verified Yesterday",
        usesText: "61 Uses Today",
        BoxDesc: "Avail this offer and save big bucks on your reliable and cost-effective RDP services.",
        btnLink: "https://homerdp.com/",
        modalDesc: "Copy & paste the following code at checkout on",
        modalCode: "R6D6P6",
    },
    {
        id: 6,
        offText: "20% Off",
        rdpName: "GPURDP",
        cardTitle: "20% OFF on DS/Monthly billing cycle",
        verifyText: "Verified Yesterday",
        usesText: "61 Uses Today",
        BoxDesc: "Avail this offer and save big bucks on your reliable and cost-effective RDP services.",
        btnLink: "https://gpurdp.com/",
        modalDesc: "Copy & paste the following code at checkout on",
        modalCode: "R6D6P6",
    },
]

export default CyberMondayList