import React from 'react'
import { Link } from 'react-router-dom'
import ThemeBtn from '../ThemeBtn/ThemeBtn'

import './ReviewsPage.scss'

import CheckIcon from '../../img/CircleCheck.png'

function ReviewsCard(props) {
    return (
        <div className='ReviewsCard_otr'>
            <div class="review_card_inr">
                <div class="card_name">
                    <Link to={props.headLink} target="_blank" class="head_name heading_h2">{props.headText}</Link>
                </div>
                <div class="rating_main">
                    <div class="feature_otr">
                        <div class="feature_inr">
                            <div class="img_otr">
                                <img class="circle_img" src={CheckIcon} alt="CircleCheck" />
                            </div>
                            <div class="feature_desc">
                                <p class="desc_inr heading_SB">{props.featureText1}</p>
                            </div>
                        </div>
                        <div class="feature_inr">
                            <div class="img_otr">
                                <img class="circle_img" src={CheckIcon} alt="CircleCheck" />
                            </div>
                            <div class="feature_desc">
                                <p class="desc_inr heading_SB">{props.featureText2}</p>
                            </div>
                        </div>
                        <div class="feature_inr">
                            <div class="img_otr">
                                <img class="circle_img" src={CheckIcon} alt="CircleCheck" />
                            </div>
                            <div class="feature_desc">
                                <p class="desc_inr heading_SB">{props.featureText3}</p>
                            </div>
                        </div>
                        <div class="feature_inr">
                            <div class="img_otr">
                                <img class="circle_img" src={CheckIcon} alt="CircleCheck" />
                            </div>
                            <div class="feature_desc">
                                <p class="desc_inr heading_SB">{props.featureText4}</p>
                            </div>
                        </div>
                    </div>
                    <div class="rating_otr">
                        <h3 class="heading_h3 rate">{props.ratingNum} <span class="heading_SB users">({props.userText} users)</span></h3>
                        <div class="stars_main">
                            <li class="star_li">
                                <svg class="star" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.6845 7.61025C18.6065 7.36526 18.4564 7.14948 18.2539 6.99106C18.0514 6.83264 17.8059 6.73894 17.5493 6.72219L12.9094 6.39748L11.1882 2.06337C11.0951 1.82376 10.9317 1.61788 10.7195 1.4727C10.5074 1.32752 10.2563 1.24981 9.99924 1.24977H9.99802C9.74134 1.25023 9.49077 1.32813 9.27908 1.47329C9.06739 1.61844 8.90442 1.8241 8.8115 2.06337L7.06467 6.4225L2.45041 6.72219C2.19384 6.73894 1.94828 6.83264 1.74577 6.99106C1.54326 7.14948 1.39319 7.36526 1.31516 7.61025C1.23284 7.85871 1.22724 8.12621 1.29909 8.3779C1.37094 8.62959 1.51691 8.85382 1.71799 9.02138L5.2678 12.0219L4.21189 16.1753C4.13911 16.4551 4.15249 16.7503 4.25029 17.0224C4.34808 17.2944 4.52573 17.5306 4.75998 17.7C4.98614 17.8645 5.2566 17.9572 5.53615 17.9659C5.8157 17.9746 6.0914 17.8989 6.32736 17.7488L9.98947 15.4289C9.99436 15.4252 9.99924 15.4227 10.0102 15.4289L13.9507 17.9252C14.1646 18.0619 14.4148 18.1308 14.6685 18.123C14.9222 18.1151 15.1677 18.0309 15.3728 17.8812C15.585 17.7278 15.746 17.5139 15.8347 17.2674C15.9233 17.021 15.9355 16.7535 15.8696 16.5L14.7478 11.9627L18.2817 9.02138C18.4828 8.85382 18.6288 8.62959 18.7006 8.3779C18.7725 8.1262 18.7669 7.85871 18.6845 7.61025Z" fill="#DAD420" />
                                </svg>
                            </li>
                            <li class="star_li">
                                <svg class="star" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.6845 7.61025C18.6065 7.36526 18.4564 7.14948 18.2539 6.99106C18.0514 6.83264 17.8059 6.73894 17.5493 6.72219L12.9094 6.39748L11.1882 2.06337C11.0951 1.82376 10.9317 1.61788 10.7195 1.4727C10.5074 1.32752 10.2563 1.24981 9.99924 1.24977H9.99802C9.74134 1.25023 9.49077 1.32813 9.27908 1.47329C9.06739 1.61844 8.90442 1.8241 8.8115 2.06337L7.06467 6.4225L2.45041 6.72219C2.19384 6.73894 1.94828 6.83264 1.74577 6.99106C1.54326 7.14948 1.39319 7.36526 1.31516 7.61025C1.23284 7.85871 1.22724 8.12621 1.29909 8.3779C1.37094 8.62959 1.51691 8.85382 1.71799 9.02138L5.2678 12.0219L4.21189 16.1753C4.13911 16.4551 4.15249 16.7503 4.25029 17.0224C4.34808 17.2944 4.52573 17.5306 4.75998 17.7C4.98614 17.8645 5.2566 17.9572 5.53615 17.9659C5.8157 17.9746 6.0914 17.8989 6.32736 17.7488L9.98947 15.4289C9.99436 15.4252 9.99924 15.4227 10.0102 15.4289L13.9507 17.9252C14.1646 18.0619 14.4148 18.1308 14.6685 18.123C14.9222 18.1151 15.1677 18.0309 15.3728 17.8812C15.585 17.7278 15.746 17.5139 15.8347 17.2674C15.9233 17.021 15.9355 16.7535 15.8696 16.5L14.7478 11.9627L18.2817 9.02138C18.4828 8.85382 18.6288 8.62959 18.7006 8.3779C18.7725 8.1262 18.7669 7.85871 18.6845 7.61025Z" fill="#DAD420" />
                                </svg>
                            </li>
                            <li class="star_li">
                                <svg class="star" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.6845 7.61025C18.6065 7.36526 18.4564 7.14948 18.2539 6.99106C18.0514 6.83264 17.8059 6.73894 17.5493 6.72219L12.9094 6.39748L11.1882 2.06337C11.0951 1.82376 10.9317 1.61788 10.7195 1.4727C10.5074 1.32752 10.2563 1.24981 9.99924 1.24977H9.99802C9.74134 1.25023 9.49077 1.32813 9.27908 1.47329C9.06739 1.61844 8.90442 1.8241 8.8115 2.06337L7.06467 6.4225L2.45041 6.72219C2.19384 6.73894 1.94828 6.83264 1.74577 6.99106C1.54326 7.14948 1.39319 7.36526 1.31516 7.61025C1.23284 7.85871 1.22724 8.12621 1.29909 8.3779C1.37094 8.62959 1.51691 8.85382 1.71799 9.02138L5.2678 12.0219L4.21189 16.1753C4.13911 16.4551 4.15249 16.7503 4.25029 17.0224C4.34808 17.2944 4.52573 17.5306 4.75998 17.7C4.98614 17.8645 5.2566 17.9572 5.53615 17.9659C5.8157 17.9746 6.0914 17.8989 6.32736 17.7488L9.98947 15.4289C9.99436 15.4252 9.99924 15.4227 10.0102 15.4289L13.9507 17.9252C14.1646 18.0619 14.4148 18.1308 14.6685 18.123C14.9222 18.1151 15.1677 18.0309 15.3728 17.8812C15.585 17.7278 15.746 17.5139 15.8347 17.2674C15.9233 17.021 15.9355 16.7535 15.8696 16.5L14.7478 11.9627L18.2817 9.02138C18.4828 8.85382 18.6288 8.62959 18.7006 8.3779C18.7725 8.1262 18.7669 7.85871 18.6845 7.61025Z" fill="#DAD420" />
                                </svg>
                            </li>
                            <li class="star_li">
                                <svg class="star" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.6845 7.61025C18.6065 7.36526 18.4564 7.14948 18.2539 6.99106C18.0514 6.83264 17.8059 6.73894 17.5493 6.72219L12.9094 6.39748L11.1882 2.06337C11.0951 1.82376 10.9317 1.61788 10.7195 1.4727C10.5074 1.32752 10.2563 1.24981 9.99924 1.24977H9.99802C9.74134 1.25023 9.49077 1.32813 9.27908 1.47329C9.06739 1.61844 8.90442 1.8241 8.8115 2.06337L7.06467 6.4225L2.45041 6.72219C2.19384 6.73894 1.94828 6.83264 1.74577 6.99106C1.54326 7.14948 1.39319 7.36526 1.31516 7.61025C1.23284 7.85871 1.22724 8.12621 1.29909 8.3779C1.37094 8.62959 1.51691 8.85382 1.71799 9.02138L5.2678 12.0219L4.21189 16.1753C4.13911 16.4551 4.15249 16.7503 4.25029 17.0224C4.34808 17.2944 4.52573 17.5306 4.75998 17.7C4.98614 17.8645 5.2566 17.9572 5.53615 17.9659C5.8157 17.9746 6.0914 17.8989 6.32736 17.7488L9.98947 15.4289C9.99436 15.4252 9.99924 15.4227 10.0102 15.4289L13.9507 17.9252C14.1646 18.0619 14.4148 18.1308 14.6685 18.123C14.9222 18.1151 15.1677 18.0309 15.3728 17.8812C15.585 17.7278 15.746 17.5139 15.8347 17.2674C15.9233 17.021 15.9355 16.7535 15.8696 16.5L14.7478 11.9627L18.2817 9.02138C18.4828 8.85382 18.6288 8.62959 18.7006 8.3779C18.7725 8.1262 18.7669 7.85871 18.6845 7.61025Z" fill="#DAD420" />
                                </svg>
                            </li>
                            <li class="star_li">
                                <svg class="star" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.6845 7.61025C18.6065 7.36526 18.4564 7.14948 18.2539 6.99106C18.0514 6.83264 17.8059 6.73894 17.5493 6.72219L12.9094 6.39748L11.1882 2.06337C11.0951 1.82376 10.9317 1.61788 10.7195 1.4727C10.5074 1.32752 10.2563 1.24981 9.99924 1.24977H9.99802C9.74134 1.25023 9.49077 1.32813 9.27908 1.47329C9.06739 1.61844 8.90442 1.8241 8.8115 2.06337L7.06467 6.4225L2.45041 6.72219C2.19384 6.73894 1.94828 6.83264 1.74577 6.99106C1.54326 7.14948 1.39319 7.36526 1.31516 7.61025C1.23284 7.85871 1.22724 8.12621 1.29909 8.3779C1.37094 8.62959 1.51691 8.85382 1.71799 9.02138L5.2678 12.0219L4.21189 16.1753C4.13911 16.4551 4.15249 16.7503 4.25029 17.0224C4.34808 17.2944 4.52573 17.5306 4.75998 17.7C4.98614 17.8645 5.2566 17.9572 5.53615 17.9659C5.8157 17.9746 6.0914 17.8989 6.32736 17.7488L9.98947 15.4289C9.99436 15.4252 9.99924 15.4227 10.0102 15.4289L13.9507 17.9252C14.1646 18.0619 14.4148 18.1308 14.6685 18.123C14.9222 18.1151 15.1677 18.0309 15.3728 17.8812C15.585 17.7278 15.746 17.5139 15.8347 17.2674C15.9233 17.021 15.9355 16.7535 15.8696 16.5L14.7478 11.9627L18.2817 9.02138C18.4828 8.85382 18.6288 8.62959 18.7006 8.3779C18.7725 8.1262 18.7669 7.85871 18.6845 7.61025Z" fill="#DAD420" />
                                </svg>
                            </li>
                        </div>
                    </div>
                </div>
                <div class="starting_from">
                    <div class="starting_from_inr">
                        <p class="heading_S starting">Starting from</p>
                        <h2 class="heading_h2 price">{props.Cardprice}</h2>
                        <Link class="action" to={props.headLink} target="_blank">
                            <ThemeBtn
                                ButtonClass="btn_fill btn_submit"
                                ButtonText="Visit Website"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewsCard