import React from 'react'
import DealsCard from '../DealsSection/DealsCard';

function AllCategoryCard() {
    const AllCategoryData = [
        {
            id: 0,
            offText: "10% Off",
            rdpName: "99RDP",
            cardTitle: "Now Get 10% off on All Plans.",
            verifyText: "Verified Yesterday",
            usesText: "61 Uses Today",
            BoxDesc: "Avail this offer and save big bucks on your reliable and cost-effective RDP services.",
            btnLink: "https://99rdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "R6D6P6",
        },
        {
            id: 1,
            offText: "15% Off",
            rdpName: "AmazingRDP",
            cardTitle: "AMD Beast Dedicated RDP $15 Off",
            verifyText: "Verified 1 Day Ago",
            usesText: "61 Uses Today",
            BoxDesc: "32-Core 64-T @3 GHz 128 GB DDR4 ECC RAM 1 Gbps Private Port 4 TB NVMe SSD Disk Unlimited Bandwidth Windows Server 2019 Location of Germany/Finland",
            btnLink: "https://amazingrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "A6R6D6P6",
        },
        {
            id: 2,
            offText: "5% Off",
            rdpName: "GPURDP",
            cardTitle: "Affordable GPU Shared RDP $5 off",
            verifyText: "Verified 3 Days Ago",
            usesText: "7 Uses Today",
            BoxDesc: "Want To Run Emulators? Want To Do Graphics Intensive Work? No Worries We Got It Covered For You.",
            btnLink: "https://gpurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Renewal5",
        },
        {
            id: 3,
            offText: "10% Off",
            rdpName: "EURRDP",
            cardTitle: "Now Get 10% Off on All Monthly Plans.",
            verifyText: "Verified 31 days ago",
            usesText: "3 Uses Today",
            BoxDesc: "Get an all-round year of quality RDP services at a whopping 10% off",
            btnLink: "https://eurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "AmazingR5",
        },
        {
            id: 4,
            offText: "15% Off",
            rdpName: "99Server",
            cardTitle: "Now Get 15% Off on Mexico Dedicated Server.",
            verifyText: "Verified 11 Days Ago",
            usesText: "34 Uses Today",
            BoxDesc: "Get an all-round year of quality RDP services at a whopping 15% off",
            btnLink: "https://99server.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Renewal5",
        },
        {
            id: 5,
            offText: "5% Off",
            rdpName: "99RDP",
            cardTitle: "Get 5% Off Each Time On Every Renewal.",
            verifyText: "Verified 2 Days Ago",
            usesText: "30 Uses Today",
            BoxDesc: "Quam euismod elementum adipiscing mauris amet in tellus. Turpis enim id varius tincidunt.",
            btnLink: "https://99rdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "AmazDGgR5",
        },
        {
            id: 6,
            offText: "5% Off",
            rdpName: "GoRDP",
            cardTitle: "Now Get 5% Off On All New purchases.",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Struggling to find new RDP services? Well, GoRDP is set to offer you all the amazing perks alongside a 5% off on every new purchase.",
            btnLink: "https://gordp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "R6DTG6P6",
        },
        {
            id: 7,
            offText: "10% Off",
            rdpName: "AmazingRDP",
            cardTitle: "Powerful GPU Dedicated Server. $10 off",
            verifyText: "Verified 8 Days Ago",
            usesText: "9 Uses Today",
            BoxDesc: "Powerful GPU Dedicated Server with GeForce® GTX 1080 configured so that you can run android emulators and online games smoothly.Get It At Just $159/m After Applying Coupon Code NVIDIA$10",
            btnLink: "https://gpurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "RenAASwal5",
        },
        {
            id: 8,
            offText: "10% Off",
            rdpName: "RDP Database",
            cardTitle: "Now Get 10% Off on All Monthly Plans.",
            verifyText: "Verified 31 Days Ago",
            usesText: "70 Uses Today",
            BoxDesc: "Tempus tincidunt senectus lacinia pulvinar vitae fermentum vel digni risus. Cursus malesuada.",
            btnLink: "https://rdpdatabase.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "AmaengR5",
        },
        {
            id: 9,
            offText: "15% Off",
            rdpName: "AmazingRDP",
            cardTitle: "15% Off on Residential RDP",
            verifyText: "Verified 2 Days Ago",
            usesText: "21 Uses Today",
            BoxDesc: "Did you expect RDP renewal at such decent rates? Instantly use this code for a 5% off on renewal.",
            btnLink: "https://amazingrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "AmDUTSR5",
        },
        {
            id: 10,
            offText: "5% Off",
            rdpName: "GoRDP",
            cardTitle: "Affordable GPU Shared RDP $5 off",
            verifyText: "Verified 8 Days Ago",
            usesText: "7 Uses Today",
            BoxDesc: "Want To Run Emulators? Want To Do Graphics Intensive Work? No Worries We Got It Covered For You.",
            btnLink: "https://gordp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "AmDUTS765",
        },
        {
            id: 11,
            offText: "10% Off",
            rdpName: "99RDP",
            cardTitle: "Get 10% Off On the Streaming Plan.",
            verifyText: "Verified 4 Days Ago",
            usesText: "300 Uses Today",
            BoxDesc: "To get great speed and guaranteed quality services, get streaming plans from 99RDP at 10% off.",
            btnLink: "https://99rdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "EDIFS765",
        },
        {
            id: 12,
            offText: "25% Off",
            rdpName: "AmazingRDP",
            cardTitle: "Get 25% instant off on all plans",
            verifyText: "Verified 5 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Sale is Again Here Buy Any RDP Plan From Us & Get 25% Off Instantly Hurry!! Get The Benefits While This Sale Lasts",
            btnLink: "https://amazingrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "AKYsj5",
        },
        {
            id: 13,
            offText: "5% Off",
            rdpName: "GPURDP",
            cardTitle: "Affordable GPU Shared RDP $5 off",
            verifyText: "Verified 8 Days Ago",
            usesText: "7 Uses Today",
            BoxDesc: "Want To Run Emulators? Want To Do Graphics Intensive Work? No Worries We Got It Covered For You.",
            btnLink: "https://gpurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "KYGst93",
        },
        {
            id: 14,
            offText: "5% Off",
            rdpName: "99RDP",
            cardTitle: "Now Get 5% Off On All New purchases.",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Struggling to find new RDP services? Well, 99RDP is set to offer you all the amazing perks alongside a 5% off on every new purchase.",
            btnLink: "https://99rdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "KYGpd23",
        },
        {
            id: 15,
            offText: "10% Off",
            rdpName: "AmazingRDP",
            cardTitle: "Perfect Beast Dedicated RDP $10 Off",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Get Flat $10 Off On Beast Dedicated Rdp AMD EPYC 7502P 32 Core 64 Threads Full Admin Access Location: Europe.",
            btnLink: "https://amazingrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PDYvsvd23",
        },
        {
            id: 16,
            offText: "30% Off",
            rdpName: "99Server",
            cardTitle: "Get 30% Off Each Time On GPU Dedicated Server.",
            verifyText: "Verified 2 Days Ago",
            usesText: "30 Uses Today",
            BoxDesc: "Want to renew RDP services? Now get a 30% off every time you seek a renewal.",
            btnLink: "https://99server.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "sjyKGT3",
        },
        {
            id: 17,
            offText: "12% Off",
            rdpName: "99RDP",
            cardTitle: "Get 12% Off On All Quarterly plans.",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "With a long term RDP plan, you can now save much more than before. Avail a 12% off on all quarterly plans.",
            btnLink: "https://99rdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "KIFrts",
        },
        {
            id: 18,
            offText: "15% Off",
            rdpName: "PCRDP",
            cardTitle: "15% Off on Residential RDP",
            verifyText: "Verified 2 Days Ago",
            usesText: "41 Uses Today",
            BoxDesc: "Did you expect RDP renewal at such decent rates? Instantly use this code for a 5% off on renewal.",
            btnLink: "https://pcrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "KIFrts",
        },
        {
            id: 19,
            offText: "20% Off",
            rdpName: "AmazingRDP",
            cardTitle: "20% off on VPS Plans",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Get Flat 20% Off On All Quarterly VPS Plans. Use Code : SUMMERSALE Hurry! Offer Valid Till 20th August.",
            btnLink: "https://amazingrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "sKYfts3",
        },
        {
            id: 20,
            offText: "10%",
            rdpName: "GPURDP",
            cardTitle: "Powerful GPU Dedicated Server. $10 off",
            verifyText: "Verified 8 Days Ago",
            usesText: "9 Uses Today",
            BoxDesc: "Powerful GPU Dedicated Server with GeForce® GTX 1080 configured so that you can run android emulators and online games smoothly.Get It At Just $159/m After Applying Coupon Code NVIDIA$10",
            btnLink: "https://gpurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "BGSSSFS",
        },
        {
            id: 21,
            offText: "15%",
            rdpName: "OliveVPS",
            cardTitle: "Now Get 15% Off on All Monthly Plans.",
            verifyText: "Verified 7 Days Ago",
            usesText: "27 Uses Today",
            BoxDesc: "Want To Run Emulators? Want To Do Graphics Intensive Work? No Worries We Got It Covered For You.",
            btnLink: "https://olivevps.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "pajdsjbcfd",
        },
        {
            id: 22,
            offText: "10%",
            rdpName: "HomeRDP",
            cardTitle: "Now Get 10% Off on All Monthly plans",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Planning on testing our RDP services by taking a monthly plan? Well, now you can get all monthly plans at 10%.",
            btnLink: "https://homerdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Nshsagds",
        },
        {
            id: 23,
            offText: "20%",
            rdpName: "RDP Database",
            cardTitle: "Get Flat 20% Off On Hong Kong RDP Hurrry!!",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Hope This Year is Filled With Love, Laughter and Light Grab This One More Great Offer From RDP Database Get Flat 20% Off On Hong Kong RDP Hurrry!!",
            btnLink: "https://rdpdatabase.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "HGDbjsd",
        },
        {
            id: 24,
            offText: "5%",
            rdpName: "EURRDP",
            cardTitle: "Get 5% Off Each Time On Every Renewal.",
            verifyText: "Verified 1 Days Ago",
            usesText: "71 Uses Today",
            BoxDesc: "Having taken first-hand experience of our RDP services, are you planning a renewal? Well, we have got a 5% off coupon code for you on every renewal.",
            btnLink: "https://eurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "LODJhbc",
        },
        {
            id: 25,
            offText: "25%",
            rdpName: "OliveVPS",
            cardTitle: "Get 25% Off On All New purchases.",
            verifyText: "Verified 5 Days Ago",
            usesText: "46 Uses Today",
            BoxDesc: "Get an all-round year of quality RDP services at a whopping 25% off",
            btnLink: "https://olivevps.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "NBsbdhs",
        },
        {
            id: 26,
            offText: "5%",
            rdpName: "99Server",
            cardTitle: "USA Dedicated Server $5 off",
            verifyText: "Verified 8 Days Ago",
            usesText: "23 Uses Today",
            BoxDesc: "Want To Run Emulators? Want To Do Graphics Intensive Work? No Worries We Got It Covered For You.",
            btnLink: "https://99server.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Nhas656",
        },
        {
            id: 27,
            offText: "30%",
            rdpName: "AmazingRDP",
            cardTitle: "30% Off amazing VPS Sale!!",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Get Flat 30% Off On All VPS Use Code : SUPERSALE21 Offer Valid Till 20th August",
            btnLink: "https://amazingrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Mdhcbd928",
        },
        {
            id: 28,
            offText: "20%",
            rdpName: "99Server",
            cardTitle: "Get 20% Off On Europe Dedicated Server.",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "With a long term RDP plan, you can now save much more than before. Avail a 20% off on all quarterly plans.",
            btnLink: "https://99server.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Manssah83",
        },
        {
            id: 29,
            offText: "27%",
            rdpName: "RDP Database",
            cardTitle: "Powerful GPU Dedicated Server. $27 off",
            verifyText: "Verified 8 Days Ago",
            usesText: "30 Uses Today",
            BoxDesc: "Powerful GPU Dedicated Server with GeForce® GTX 1080 configured so that you can run android emulators and online games smoothly.Get It At Just $159/m After Applying Coupon Code L5K597t5i7",
            btnLink: "https://rdpdatabase.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "jsdhsbUHD",
        },
        {
            id: 30,
            offText: "5%",
            rdpName: "GPURDP",
            cardTitle: "Affordable GPU Shared RDP $5 off",
            verifyText: "Verified 8 Days Ago",
            usesText: "7 Uses Today",
            BoxDesc: "Want To Run Emulators? Want To Do Graphics Intensive Work? No Worries We Got It Covered For You.",
            btnLink: "https://gpurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Ljdfdh234",
        },
        {
            id: 31,
            offText: "25%",
            rdpName: "99Server",
            cardTitle: "Get 25% Off Each Time On Netherlands Dedicated Server.",
            verifyText: "Verified 1 Days Ago",
            usesText: "71 Uses Today",
            BoxDesc: "Having taken first-hand experience of our RDP services, are you planning a renewal? Well, we have got a 25% off coupon code for you on every renewal.",
            btnLink: "https://99server.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Manjdsdb",
        },
        {
            id: 32,
            offText: "5%",
            rdpName: "HomeRDP",
            cardTitle: "Get 5% Off On All New purchases.",
            verifyText: "Verified 1 Days Ago",
            usesText: "71 Uses Today",
            BoxDesc: "Get authentic, transformational RDP services for your business now. Avail 5% off by using our coupon code. Offer applicable only for new users.",
            btnLink: "https://homerdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Kadhhf783",
        },
        {
            id: 33,
            offText: "22% Off",
            rdpName: "PCRDP",
            cardTitle: "Now Get 22% Off Each Time On Every Renewal.",
            verifyText: "Verified 3 Days Ago",
            usesText: "55 Uses Today",
            BoxDesc: "Intending to renew services we offer? Now you can do it instantly with a tempting offer at hand. Get 22% off each time you get a renewal.",
            btnLink: "https://pcrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Kdncdhs",
        },
        {
            id: 34,
            offText: "30%",
            rdpName: "OliveVPS",
            cardTitle: "Get Flat 30% Off On Hong Kong RDP Hurrry!!",
            verifyText: "Verified 6 Days Ago",
            usesText: "27 Uses Today",
            BoxDesc: "With a long term RDP plan, you can now save much more than before. Avail a 30% off on all quarterly plans.",
            btnLink: "https://olivevps.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Mksjd896",
        },
        {
            id: 35,
            offText: "20%",
            rdpName: "AmazingRDP",
            cardTitle: "Get Flat 20% Off On Hong Kong RDP Hurrry!!",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Hope This Year is Filled With Love, Laughter and Light Grab This One More Great Offer From AmazingRdp Get Flat 20% Off On Hong Kong RDP Hurrry!!",
            btnLink: "https://amazingrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Manxjhsc",
        },
        {
            id: 36,
            offText: "20% Off",
            rdpName: "PCRDP",
            cardTitle: "Now Get 20% Off On All Quarterly plans.",
            verifyText: "Verified 1 Days Ago",
            usesText: "71 Uses Today",
            BoxDesc: "Take a prolonged plan and save some extra money. Get RDP quarterly plans at 20% off.",
            btnLink: "https://pcrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "nv976dfhv",
        },
        {
            id: 37,
            offText: "12%",
            rdpName: "HomeRDP",
            cardTitle: "Get 12% Off On All Quarterly plans.",
            verifyText: "Verified 2 Days Ago",
            usesText: "11 Uses Today",
            BoxDesc: "Cover your quarterly needs by taking a quarterly plan at 12% off.",
            btnLink: "https://homerdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "mnvdfhv",
        },
        {
            id: 38,
            offText: "12% Off",
            rdpName: "GoRDP",
            cardTitle: "Get 12% Off On All Quarterly plans.",
            verifyText: "Verified 1 Days Ago",
            usesText: "71 Uses Today",
            BoxDesc: "Take a prolonged plan and save some extra money. Get RDP quarterly plans at 12% off.",
            btnLink: "https://gordp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Mnsbhdf97",
        },
        {
            id: 39,
            offText: "5%",
            rdpName: "EURRDP",
            cardTitle: "Get 5% Off On All New Purchase.",
            verifyText: "Verified 1 Days Ago",
            usesText: "71 Uses Today",
            BoxDesc: "Avail a 5% off on amazing RDP plans for new subscriptions.",
            btnLink: "https://eurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "nc54dcb",
        },
        {
            id: 40,
            offText: "16%",
            rdpName: "RDP Database",
            cardTitle: "Get 16% Off On All New purchases.",
            verifyText: "Verified 1 Days Ago",
            usesText: "75 Uses Today",
            BoxDesc: "Get authentic, transformational RDP services for your business now. Avail 16% off by using our coupon code. Offer applicable only for new users.",
            btnLink: "https://rdpdatabase.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Kshydtvb",
        },
        {
            id: 41,
            offText: "5%",
            rdpName: "AmazingRDP",
            cardTitle: "5% off Renew your RDP/VPS NOW !!",
            verifyText: "Verified 4 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Use code AmazingR5 and Renew your RDP/VPS NOW !!",
            btnLink: "https://amazingrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Lsjucdb64",
        },
        {
            id: 42,
            offText: "12%",
            rdpName: "EURRDP",
            cardTitle: "Now Get 12% Off On All Quarterly plans.",
            verifyText: "Verified 1 Days Ago",
            usesText: "71 Uses Today",
            BoxDesc: "Take a prolonged plan and save some extra money. Get RDP quarterly plans at 12% off.",
            btnLink: "https://eurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Pksijd73",
        },
        {
            id: 43,
            offText: "25%",
            rdpName: "RDP Database",
            cardTitle: "Get 25% Off on All Annually plans.",
            verifyText: "Verified 3 Days Ago",
            usesText: "37 Uses Today",
            BoxDesc: "Running low on business’ yearly budget? Save more at the RDP front by taking a wholesome annual plan at 25% off.",
            btnLink: "https://rdpdatabase.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Kshjsdbcb",
        },
        {
            id: 44,
            offText: "35%",
            rdpName: "OliveVPS",
            cardTitle: "Powerful GPU Dedicated Server. $35 off",
            verifyText: "Verified 2 Days Ago",
            usesText: "20 Uses Today",
            BoxDesc: "Want to renew RDP services? Now get a 35% off every time you seek a renewal.",
            btnLink: "https://olivevps.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "djbfdhb6",
        },
        {
            id: 45,
            offText: "20%",
            rdpName: "HomeRDP",
            cardTitle: "Get 20% Off on All Annually plans.",
            verifyText: "Verified 3 Days Ago",
            usesText: "17 Uses Today",
            BoxDesc: "Running low on business’ yearly budget? Save more at the RDP front by taking a wholesome annual plan at 20% off.",
            btnLink: "https://homerdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Kkbchd276",
        },
        {
            id: 46,
            offText: "10% Off",
            rdpName: "PCRDP",
            cardTitle: "Get 10% Off On the Streaming Plan.",
            verifyText: "Verified 4 Days Ago",
            usesText: "300 Uses Today",
            BoxDesc: "To get great speed and guaranteed quality services, get streaming plans from PCRDP at 10% off.",
            btnLink: "https://pcrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "kjsugdv56",
        },
        {
            id: 47,
            offText: "5%",
            rdpName: "HomeRDP",
            cardTitle: "Now Get 5% Off Each Time On Every Renewal.",
            verifyText: "Verified 3 Days Ago",
            usesText: "35 Uses Today",
            BoxDesc: "Intending to renew services we offer? Now you can do it instantly with a tempting offer at hand. Get 5% off each time you get a renewal.",
            btnLink: "https://homerdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "lsjsc276",
        },
        {
            id: 48,
            offText: "20% Off",
            rdpName: "GoRDP",
            cardTitle: "Now Get 20% Off on All Annually Plan.",
            verifyText: "Verified 31 Days Ago",
            usesText: "3 Uses Today",
            BoxDesc: "Get an all-round year of quality RDP services at a whopping 20% off.",
            btnLink: "https://gordp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "M76mdnfjdfb",
        },
        {
            id: 49,
            offText: "20%",
            rdpName: "EURRDP",
            cardTitle: "Now Get 20% Off on All Annually Plan.",
            verifyText: "Verified 31 Days Ago",
            usesText: "3 Uses Today",
            BoxDesc: "Get an all-round year of quality RDP services at a whopping 20% off",
            btnLink: "https://eurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Pkspjd3",
        },
        {
            id: 50,
            offText: "5%",
            rdpName: "OliveVPS",
            cardTitle: "Get 5% Off Each Time On All Annually plans.",
            verifyText: "Verified 1 Days Ago",
            usesText: "41 Uses Today",
            BoxDesc: "Having taken first-hand experience of our RDP services, are you planning a renewal? Well, we have got a 5% off coupon code for you on every renewal.",
            btnLink: "https://olivevps.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "lkdsjbHT09",
        },
        {
            id: 51,
            offText: "10%",
            rdpName: "RDP Database",
            cardTitle: "Now Get 10% Off on All Monthly Plans.",
            verifyText: "Verified 31 Days Ago",
            usesText: "70 Uses Today",
            BoxDesc: "Get an all-round year of quality RDP services at a whopping 10% off",
            btnLink: "https://rdpdatabase.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "askdhshs10",
        },
        {
            id: 52,
            offText: "5% Off",
            rdpName: "ServerCafe",
            cardTitle: "USA Dedicated Server $5 off",
            verifyText: "Verified 8 Days Ago",
            usesText: "28 Uses Today",
            BoxDesc: "Customizable Dedicated Server Solutions for Your Unique Needs.",
            btnLink: "https://serverscafe.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Tdafr976",
        },
        {
            id: 53,
            offText: "10% Off",
            rdpName: "ServerCafe",
            cardTitle: "Now Get 10% Off on Mexico Dedicated Server.",
            verifyText: "Verified 18 Days Ago",
            usesText: "34 Uses Today",
            BoxDesc: "We offer dedicated server solutions tailored to meet your unique requirements and enhance your business operations.",
            btnLink: "https://serverscafe.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Fjahki87",
        },
        {
            id: 54,
            offText: "15% Off",
            rdpName: "ServerCafe",
            cardTitle: "Get 15% Off On Europe Dedicated Server.",
            verifyText: "Verified 4 Days Ago",
            usesText: "28 Uses Today",
            BoxDesc: "Customizable Server Options Choose from a range of processor, storage, and RAM options to build a server that meets your specific needs.",
            btnLink: "https://serverscafe.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "XKhsgd",
        },
        {
            id: 55,
            offText: "20% Off",
            rdpName: "ServerCafe",
            cardTitle: "Get 20% Off Each Time On Netherlands Dedicated Server.",
            verifyText: "Verified 1 Days Ago",
            usesText: "71 Uses Today",
            BoxDesc: "Get High-Performance Hardware Our dedicated servers are built with the latest hardware to ensure maximum performance and reliability.",
            btnLink: "https://serverscafe.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Hfagva",
        },
        {
            id: 56,
            offText: "25% Off",
            rdpName: "ServerCafe",
            cardTitle: "Get 25% Off Each Time On GPU Dedicated Server.",
            verifyText: "Verified 2 Days Ago",
            usesText: "30 Uses Today",
            BoxDesc: "Get Scalable Server Solutions As your business grows, our dedicated servers can be easily scaled to meet your changing requirements.",
            btnLink: "https://serverscafe.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "Dafdayt",
        },
        {
            id: 57,
            offText: "15% Off",
            rdpName: "serverscafe",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://serverscafe.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 58,
            offText: "15% Off",
            rdpName: "99RDP",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://99rdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 59,
            offText: "15% Off",
            rdpName: "AmazingRDP",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://amazingrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 60,
            offText: "15% Off",
            rdpName: "EURRDP",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://eurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 61,
            offText: "15% Off",
            rdpName: "HomeRDP",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://homerdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 62,
            offText: "15% Off",
            rdpName: "GPURDP",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://gpurdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 63,
            offText: "15% Off",
            rdpName: "RDP Database",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://rdpdatabase.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 64,
            offText: "15% Off",
            rdpName: "PCRDP",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://pcrdp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 65,
            offText: "15% Off",
            rdpName: "GoRDP",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://gordp.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 66,
            offText: "15% Off",
            rdpName: "99server",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://99server.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 67,
            offText: "15% Off",
            rdpName: "olivevps",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://olivevps.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        },
        {
            id: 68,
            offText: "15% Off",
            rdpName: "buyrdplive",
            cardTitle: "Now Get 15% Off on All Plans - Elevate Your Experience with Secure and Efficient RDPs!",
            verifyText: "Verified 1 Days Ago",
            usesText: "5 Uses Today",
            BoxDesc: "Experience the next level of productivity with our diverse range of Remote Desktop Plans. Unlock a 15% discount on all plans, ensuring secure and efficient remote access tailored to your needs. Elevate your digital journey and enjoy seamless connectivity today!",
            btnLink: "https://www.buyrdplive.com/",
            modalDesc: "Copy & paste the following code at checkout on",
            modalCode: "PLANSAVER15",
        }
    ]

    return (
        <>
            {AllCategoryData.map((val) => {
                return (
                    <DealsCard
                        id={val.id}
                        offText={val.offText}
                        rdpName={val.rdpName}
                        cardTitle={val.cardTitle}
                        verifyText={val.verifyText}
                        usesText={val.usesText}
                        BoxDesc={val.BoxDesc}
                        btnLink={val.btnLink}
                        modalDesc={val.modalDesc}
                        modalCode={val.modalCode}
                    />
                )
            })}
        </>
    )
}

export default AllCategoryCard